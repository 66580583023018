<template>
  <div class="project-card-feature__container">
    <nuxt-icon
      :name="icons[type]"
      :class="
        inactiveCard
          ? 'project-card-feature__icon--inactive'
          : 'project-card-feature__icon--active'
      "
    />
    <span class="project-card-feature__data">{{ featureData }}</span>
  </div>
</template>

<script lang="ts">
export default {
  name: "ProjectCardFeature",
  props: {
    type: { type: String, default: 0 },
    featureData: { default: '' },
    inactiveCard: { type: Boolean, default: () => false },
  },
  data() {
    return {
      icons: {
        completion: "house-w-tick",
        unitNumber: "project-house",
        sqft: "project-sqft",
        bedrooms: "project-bedrooms",
        bathrooms: "project-bathrooms",
        priceRange: "project-price-range",
      },
    };
  },
};
</script>

<style lang="scss">
.project-card-feature {
  &__container {
    display: flex;
    flex-direction: row;
    gap: 8.07px;
    font-size: 14px;
  }

  &__data {
    font-weight: 600;
    letter-spacing: 0;
  }

  &__icon {
    &--active {
      color: var(--hb-primary);
    }

    &--inactive {
      color: var(--hb-gray4);
    }
  }
}
</style>
