<template>
  <div class="project-card-features__container">
    <div class="project-card-features__features">
      <project-card-feature
        v-if="features?.unitNumber"
        type="unitNumber"
        :feature-data="features?.unitNumber"
        :inactive-card="inactiveCard"
      />
      <project-card-feature
        v-if="features?.bedrooms"
        type="bedrooms"
        :feature-data="features?.bedrooms"
        :inactive-card="inactiveCard"
      />
      <project-card-feature
        v-if="features?.bathrooms"
        type="bathrooms"
        :feature-data="features?.bathrooms"
        :inactive-card="inactiveCard"
      />
    </div>
    <div
      v-if="features?.sqft || features?.priceRange"
      class="project-card-features__range-features"
    >
      <div class="project-card-features__range-container">
        <project-card-range-item
          v-if="features?.sqft"
          type="sqft"
          :feature-data="features?.sqft"
        />
        <project-card-range-item
          v-if="features?.priceRange"
          type="priceRange"
          :feature-data="features?.priceRange"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ProjectCardFeature from "./ProjectCardFeature.vue";
import ProjectCardRangeItem from "./ProjectCardRangeItem.vue";

export default {
  name: "ProjectCardFeatures",
  components: {
    ProjectCardFeature,
    ProjectCardRangeItem,
  },
  props: {
    features: {
      type: Object as () => {
        unitNumber: string;
        bedrooms: string;
        bathrooms: string;
        sqft: string;
        priceRange: number;
      },
      default: () => {},
    },
    inactiveCard: { type: Boolean, default: () => false },
  },
};
</script>

<style lang="scss">
.project-card-features {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 14.8px;
  }

  &__features {
    display: flex;
    flex-direction: row;
    gap: 29.08px;
    margin-bottom: 17.05px;
    padding: 0 17px;
  }

  &__range-features {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 61px;
  }

  &__range-container {
    border-top: 1px solid #c2cdd1;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__range-item {
    width: 50%;
    display: flex;
    align-items: center;
    padding-left: 16px;
    max-width: 50%;

    &:last-child {
      border-left: 1px solid #c2cdd1;
    }
  }
}
</style>
