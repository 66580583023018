<template>
  <div
    :key="project.status"
    class="project-card"
    :class="[
      listItem ? 'project-card__hide-overflow' : 'project-card__padding',
      isAdmin ? '' : 'clickable',
    ]"
    @click="isAdmin ? null : onCardClick(project.slug)"
  >
    <div
      class="project-card__image"
      :class="
        listItem
          ? 'project-card__image--gradient'
          : 'project-card__image__full-rounded'
      "
      :style="
        project.status == 'inactive'
          ? `background-image: linear-gradient(black, black), url(${
              project.image || project.backupImage
                ? !isHeroVideo()
                  ? project.image
                  : project.backupImage
                : placeHolderImg
            }); background-blend-mode: saturation;`
          : `background-image: url(${
              !isHeroVideo() ? project.image : project.backupImage
            });`
      "
    >
      <div
        v-if="project?.discount && project?.discount !== ''"
        class="project-card__discount"
        :class="
          project.status == 'inactive'
            ? 'project-card__discount--inactive'
            : 'project-card__discount--active'
        "
      >
        <div class="project-card__discount--count">{{ project?.discount }}</div>
        <div class="project-card__discount--label">
          {{ $t('project.on_homebourse') }}
        </div>
      </div>
      <div v-if="listItem && isAdmin" class="project-card__admin-actions">
        <div class="project-card__admin-actions--inner">
          <div
            v-tooltip.hover="t('project.edit_project')"
            class="project-card__admin-actions--btn clickable"
            @click="onActionClick('edit', project.slug)"
          >
            <nuxt-icon name="edit" />
          </div>
          <div
            v-tooltip.hover="
              t(
                project.status == 'inactive'
                  ? 'project.activate_project'
                  : 'project.inactivate_project'
              )
            "
            class="project-card__admin-actions--btn clickable"
            @click="
              onActionClick(
                `${project.status == 'inactive' ? 'activate' : 'inactivate'}`,
                project.slug
              )
            "
          >
            <nuxt-icon
              :name="project.status == 'inactive' ? 'check' : 'close'"
              :filled="project.status == 'inactive'"
            />
          </div>
        </div>
        <div class="project-card__admin-actions--inner">
          <!-- BACKEND SHOULD BE CREATED FOR DELETE PROJECT -->
          <!-- <div
            v-tooltip.hover="t('project.delete_project')"
            class="project-card__admin-actions--btn clickable"
            @click="onActionClick('delete', project.slug)"
          >
            <nuxt-icon name="trash" filled />
          </div> -->
          <div
            v-tooltip.hover="t('project.chat_settings')"
            class="project-card__admin-actions--btn clickable"
            @click="onActionClick('chat-settings', project.slug)"
          >
            <nuxt-icon name="chat-settings" filled />
          </div>
        </div>
      </div>
      <div
        v-if="listItem && project?.address"
        class="project-card__address-on-img"
      >
        <nuxt-icon
          name="pin"
          :class="
            project.status == 'inactive'
              ? 'project-card__address-on-img--icon-inactive'
              : 'project-card__address-on-img--icon-active'
          "
        />
        <p>
          {{ project.address }}
        </p>
      </div>
    </div>
    <div v-if="!listItem" class="project-card__content">
      <div class="project-card__content--title">{{ project.name }}</div>
      <div class="project-card__content--subtitle">
        {{ project.address }}
      </div>
    </div>
    <div v-else class="project-card__features-container">
      <div class="project-card__features-container--title">
        {{ project.name }}
      </div>
      <div class="project-card__homes-counter-container">
        <project-card-features
          :features="{
            unitNumber: getValue('unitNumber') ?? null,
            bedrooms: getValue('bedrooms') ?? null,
            bathrooms: getValue('bathrooms') ?? null,
            sqft: getValue('sqft') ?? null,
            priceRange: getValue('priceRange') ?? null,
          }"
          :inactive-card="project.status == 'inactive'"
        />
      </div>
    </div>
    <div class="project-card__footer"></div>
  </div>
</template>

<script lang="ts">
import ProjectCardFeatures from './ProjectCardFeatures.vue'
import { useI18n, useProjectStore } from '#imports'
import { useRouter } from '#app'
import { HOME_PLACEHOLDER_SRC } from '~/modules/home/constants'
import { useToasts } from '~/composables'

export default {
  name: 'ProjectCard',
  components: {
    ProjectCardFeatures,
  },
  props: {
    listItem: { type: Boolean, default: () => false },
    isAdmin: { type: Boolean, default: () => false },
    project: {
      type: Object as () => {
        image: string
        name: string
        address: string
        slug: string
        status: 'active' | 'inactive'
        homeCount?: number
        discount?: string
        features: any
        backupImage?: string
      },
      default: () => {},
    },
  },
  emits: ['update-projects'],
  setup(props) {
    const { t } = useI18n()
    const router = useRouter()

    const placeHolderImg = HOME_PLACEHOLDER_SRC

    const onCardClick = (slug) => {
      // window.open(url, '_blank')
      router.push(`/project/${slug}`)
    }

    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'flv', 'webm']
    const isHeroVideo = () => {
      return videoExtensions.some((ext) => props.project?.image.includes(ext))
    }

    return {
      onCardClick,
      t,
      isHeroVideo,
      placeHolderImg,
    }
  },
  methods: {
    getValue(type: string) {
      const feature = this.project?.features?.find((f) => f.type === type)
      var value = feature?.value
      if (feature) {
        if (type == 'bedrooms') {
          if (!value) {
            value = 'S'
          } else if (Array.from(value)[0] == '0') {
            value = value.replace('0', 'S')
          }
        }
      }
      return value
    },

    async onActionClick(action, slug) {
      const router = useRouter()
      const projectStore = useProjectStore()

      if (action === 'edit') {
        router.push(`/project-admin/projects/update/${slug}`)
      } else if (action === 'delete') {
        console.log('delete project' + slug)
        // await projectStore.deleteProjectBySlug(slug)
      } else if (action === 'activate' || action === 'inactivate') {
        await projectStore
          .changeProjectStatusBySlug(
            slug,
            action === 'activate' ? 'active' : 'inactive'
          )
          .then((res) => {
            if (res.success) {
              useToasts().show({
                text: res.success ?? 'Project status updated successfully',
                theme: 'success',
              })
              this.$emit('update-projects')
            }
            if (res.error) {
              useToasts().show({
                text:
                  res.error ?? 'Something went wrong. Please try again later.',
                theme: 'error',
              })
            }
          })
          .catch((err) => {
            useToasts().show({
              text:
                err.message ?? 'Something went wrong. Please try again later.',
              theme: 'error',
            })
          })
      } else if (action === 'chat-settings') {
        router.push(`/project-admin/projects/chat-settings/${slug}`)
      }
    },
  },
}
</script>

<style lang="scss">
.project-card {
  border: 1px solid var(--hb-gray1);
  border-radius: 10px;
  background: var(--hb-white);
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  text-decoration: none;
  text-align: left;
  color: inherit;
  max-width: 300px;

  &__padding {
    padding: 15px;
  }

  &__hide-overflow {
    overflow: hidden;
  }

  @include tablet {
    border: 2px solid var(--hb-gray2);
  }

  @include mobile-sm {
    max-width: 100%;
  }

  @media (min-width: 768px) {
    &:hover {
      position: relative;
      z-index: 1;
      box-shadow: 30px 20px 99px #1d323d4d;
      border-color: #fff;
    }
  }

  &__features-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &--title {
      font-size: 24px;
      font-weight: 700;
      margin-left: 17px;
      margin-top: 12px;
    }
  }

  &__image {
    width: 100%;
    min-height: 158px;
    aspect-ratio: 258 / 158;
    // background-image: url('https://storage.googleapis.com/homebourse-public-staging/project/5/hero-image-home.jpg');
    background-size: cover;
    background-position: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;

    &__full-rounded {
      border-radius: 5px;
    }

    &--gradient::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background: transparent
        linear-gradient(185deg, #19191900 0%, #191919 100%) 0% 0% no-repeat
        padding-box;
      z-index: 1;
      opacity: 0.7;
    }

    &--gradient * {
      z-index: 2;
    }
  }

  &__address-on-img {
    color: var(--hb-white);
    font-size: 10px;
    letter-spacing: -0.3px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    position: absolute;
    bottom: 12px;
    margin: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--icon-active {
      color: var(--hb-primary);
      font-size: 12px;
    }

    &--icon-inactive {
      color: var(--hb-gray2);
      font-size: 12px;
    }
  }

  &__homes-counter-container {
    display: flex;
    align-items: center;
  }

  &__homes-text {
    font-size: 12px;
    letter-spacing: 0;
    margin-left: 4px;
  }

  &__homes-counter {
    font-size: 18px;
    letter-spacing: 0;
    font-weight: 600;
    margin-left: 11px;
  }

  &__content {
    margin-top: 15px;
    flex-grow: 1;

    &--title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 12px;
    }

    &--subtitle {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  &__discount {
    top: 9px;
    right: 9px;
    padding: 4px 8px;
    border-radius: 6px;
    position: absolute;
    // background-color: var(--hb-primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 10px;
    font-weight: 600;
    color: var(--hb-blue1);

    &--active {
      background-color: var(--hb-primary);
    }

    &--inactive {
      background-color: gray;
    }

    &--count {
      color: #fff;
      font-size: 11px;
      font-weight: 600;
    }
  }

  &__admin-actions {
    position: absolute;
    top: 9px;
    left: 9px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;

    &--inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    &--btn {
      padding: 6px 8px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s all;

      background-color: var(--hb-primary);
      color: var(--hb-white);
      font-size: 18px;
      box-shadow: 0 0 6px var(--hb-primary);
    }

    &--btn:hover {
      background-color: var(--hb-red2);
      box-shadow: unset;
    }
  }
}

.clickable {
  cursor: pointer;
}
</style>
