<template>
  <div class="project-card-features__range-item">
    <div class="project-card-range__item">
      <div class="project-card-range__label">{{ $t(getLabel()) }}</div>
      <div class="project-card-range__data">
        {{ featureData }}
        <!-- <span v-if="type === 'sqft'" class="project-card-range__sqft"
          >sqft</span
        > -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "ProjectCardRangeItem",
  props: { type: { type: String, default: 0 }, featureData: { default: "" } },
  methods: {
    getLabel() {
      return `project.feature_types.${this.type}`;
    },
  },
};
</script>

<style lang="scss">
.project-card-range {
  &__item {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-weight: 300;
  }

  &__label {
    letter-spacing: 0;
  }

  &__data {
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 600;
  }

  &__sqft {
    font-size: 12px;
    font-weight: normal;
    margin-left: 1px;
  }
}
</style>
